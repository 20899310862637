import "styles/pages/page-data-pooling.scss";

import React from "react";

import Seo from "components/Seo";
import Layout from "components/Layout";
import Accordion from "../components/Accordion";
import SectionHeading from "components/SectionHeading";
import NewContactForm from "components/NewContactForm";

const BusinessInsights = ({ pageContext }) => {
  const data_pooling = pageContext.pageContent;

  const how_system_work_items = data_pooling.howItems.map(({ desc }, key) => {
    return {
      key,
      desc,
    };
  });

  const advantages_items = data_pooling.advantagesItems.map(({ text }, key) => {
    return {
      key,
      desc: text,
    };
  });

  return (
    <Layout logo_variant="blue">
      <Seo
        title={
          pageContext.seo.title ? pageContext.seo.title : pageContext.title
        }
        description={pageContext.seo?.description}
      />
      <div className="page-datapooling">
        <section className="page-datapooling__header">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-11">
                <div className="home-hero__text-wrapper">
                  <h1 className="home-hero__title">
                    {data_pooling?.pageHeaderText}
                  </h1>
                  <div className="home-hero__text" />
                  <a href="#section-info" className="btn btn--blue btn--down">
                    See more
                  </a>
                </div>
              </div>
            </div>
          </div>
          <video autoPlay={true} muted={true} loop={true} playsInline={true}>
            <source
              src={require("assets/videos/data-pooling-hero.mp4").default}
              type="video/mp4"
            />
          </video>
        </section>

        <section id="section-info" className="section-info">
          <div className="container">
            <SectionHeading
              className="section-info__title"
              subtitle={data_pooling?.firstText}
            />
            <img
              src={data_pooling?.firstImage?.sourceUrl}
              alt=""
              className="img-fluid section-why__img"
            />
          </div>
        </section>

        <section className="section-how_system_work">
          <div className="container">
            <SectionHeading title={data_pooling?.howHeader} />
            <div className="row">
              <div className="col-lg-7">
                <Accordion
                  className="accordin"
                  visibleOnStart={true}
                  items={how_system_work_items}
                />
              </div>
              <div className="col-lg-5 section-how_system_work__image">
                <img
                  className="img-fluid"
                  src={data_pooling?.howImage?.sourceUrl}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="section-advantages">
          <div className="container">
            <SectionHeading
              className="section-advantages__title"
              title="Advantages of data pooling systems"
              color="white"
            />
            <div className="row">
              <div className="col-lg-5 section-advantages__image">
                <img
                  className="img-fluid"
                  src={data_pooling?.advantagesImage?.sourceUrl}
                />
              </div>
              <div className="col-lg-7">
                <Accordion
                  className="accordin"
                  visibleOnStart={true}
                  items={advantages_items}
                />
              </div>
            </div>
          </div>
        </section>
      </div>
      <NewContactForm
        person_variant="gary"
        form_variant="data-pooling"
        color="blue"
        sub_text={
          <>
            Please don't hesitate to contact us at{" "}
            <a href="mailto:pdp@prodinity.com" style={{ color: "#00FFFF" }}>
              pdp@prodinity.com
            </a>{" "}
            to discuss your data pooling requirements.
          </>
        }
      />
    </Layout>
  );
};

export default BusinessInsights;
